import { Component } from '@angular/core';
import { LbtSelectValue } from '../forms/lbt-select/lbt-select.component';

export class OptionValue {
  value: LbtSelectValue;
  cascadeValues: OptionValue[]|null;;

  constructor(id: number, text: string, cascadeValues: OptionValue[]|null = null) {
    this.value = new LbtSelectValue(id, text);
    this.cascadeValues = cascadeValues;
  }
}

export class DialogOption {
  protected next: DialogOption|null;
  
  constructor(name: string, values: OptionValue[], selected = null) {
    this._name = name;
    this._values = values;
    this._selected = selected;
    this.next = null;
  }
  
  private _name: string;
  public get name(): string {
    return this._name;
  }
  
  private _values: OptionValue[];
  public get values(): LbtSelectValue[] {
    return this._values.map(v => v.value);
  }

  public setNext(option: DialogOption|null) {
    this.next = option;
  }
  
  private setNewValues(values: OptionValue[]) {
    this._values = values;
    if (values.findIndex(v => v.value.id == this.selected) < 0) {
      this.selected = this._values.length == 1 ? this._values[0].value.id : null;
    }
    else {
      this.selected = this.selected; // force the set so cascade values get propagated if those have changed
    }
  }
  
  private _selected: string|number|null;
  public set selected(selected: string|number|null) {
    this._selected = selected;
    const v = this._values.find(v => v.value.id == selected);
    if (v != undefined && v.cascadeValues != null) {
      this.next?.setNewValues(v.cascadeValues);
    }
  }

  public get selected(): string|number|null {
    return this._selected;
  }
}

export class DialogInput {
  private _name: string;
  public value: string;
  private regex: RegExp;
  private _error: string;

  constructor(name: string, validRegex:RegExp = /.*/, errMessage: string = '', startValue: string = '') {
    this._name = name;
    this.value = startValue;
    this.regex = validRegex;
    this._error = errMessage;
  }

  public get name(): string {
    return this._name;
  }

  public get error(): string {
    return this._error;
  }

  public isValid(): boolean {
    return (this.value.trim().match(this.regex)) ? true : false;
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.css',
  host: {'[class.showDialog]': 'dialogShow'}
})
export class DialogComponent {
  private promise: Promise<any[]>;
  private resolve: (outputs: any[]) => void;
  private reject: (reason?: any) => void;
  protected dialogTitle: string;
  protected dialogMessage: string;
  protected dialogInputs: (DialogInput|DialogOption)[]|null;
  protected dialogStyle: string;
  protected isInfo: boolean;
  protected dialogShow = false;
  protected output: Map<string, string|number>;

  private static mainDialog: DialogComponent|null = null;
  public static registerDialog(dialog: DialogComponent): boolean {
    if (DialogComponent.mainDialog == null && dialog != undefined) {
      DialogComponent.mainDialog = dialog;
      return true;
    }
    console.error("registerDialog failed due to a dialog already being registered");
    return false;
  }

  public static unregisterDialog(dialog: DialogComponent) {
    if (DialogComponent.mainDialog == dialog) {
      DialogComponent.mainDialog = null;
      return true;
    }
    console.error("unregisterDialog failed due to a different dialog already being registered");
    return false;
  }

  // TODO: implement requests queue
  public static showDialog(title: string, message: string, style: string = ""): Promise<any[]> {
    if (DialogComponent.mainDialog) {
      return DialogComponent.mainDialog.showDialog(title, message, style);
    }
    console.error("showDialog called without a registered main dialog");
    return new Promise((_, reject) => reject());
  }

  public static showInfoDialog(title: string, message: string, style: string = ""): Promise<any[]> {
    if (DialogComponent.mainDialog) {
      return DialogComponent.mainDialog.showInfoDialog(title, message, style);
    }
    console.error("showInfoDialog called without a registered main dialog");
    return new Promise((_, reject) => reject());
  }

  public static showInputsDialog(title: string, message: string, inputs: (DialogInput|DialogOption)[], style: string = ""): Promise<any[]> {
    if (DialogComponent.mainDialog) {
      return DialogComponent.mainDialog.showInputsDialog(title, message, inputs, style);
    }
    console.error("showInputsDialog called without a registered main dialog");
    return new Promise((_, reject) => reject());
  }

  public static showError(errorId: string): Promise<any[]> {
    if (DialogComponent.mainDialog) {
      return DialogComponent.mainDialog.showError(errorId);
    }
    console.error("showError called without a registered main dialog");
    return new Promise((_, reject) => reject());
  }

  public static hideDialog(): void {
    if (DialogComponent.mainDialog) {
      DialogComponent.mainDialog.dialogShow = false;
    }
  }

  private showDialog(title: string, message: string, style: string = ""): Promise<any[]> {
    this.initDialog(title, message);
    this.dialogInputs = null;
    this.dialogStyle = style;
    return this.enableDialog();
  }

  private showInfoDialog(title: string, message: string, style: string = ""): Promise<any[]> {
    this.initDialog(title, message);
    this.dialogInputs = null;
    this.dialogStyle = style;
    this.isInfo = true; 
    return this.enableDialog();
  }

  private showInputsDialog(title: string, message: string, inputs: (DialogInput|DialogOption)[], style: string = ""): Promise<any[]> {
    this.initDialog(title, message);
    this.dialogInputs = inputs;
    this.dialogStyle = style;
    // reverse traverse the array to set next options for cascade values
    let next: DialogOption|null = null;
    for (let i = this.dialogInputs.length -1; i >= 0; --i) {
      let input = this.dialogInputs[i];
      if (input instanceof DialogOption) {
        input.setNext(next);
        next = input;
      }
    }
    return this.enableDialog();
  }

  private showError(errorId: string): Promise<any[]> {
    let message = '';
    switch (errorId)
    {
    // VOUCHERS
    // TODO: get translations from loc db
    case 'ERR_TICKET_INVALID':
      message = 'Este ticket ya ha sido cerrado.';
      break;
    case 'ERR_CODE_INVALID':
      message = 'Código inválido.';
      break;
    case 'ERR_CODE_CONSUMED':
      message = 'Vale ya usado.';
      break;
    case 'ERR_CODE_EXPIRED':
      message = 'Vale fuera de fecha de validez.';
      break;
    case 'ERR_INSUFICIENT_POINTS':
      message = 'El usuario no tiene suficientes puntos.';
      break;
    // GLOBAL
    case 'ERR_SYSTEM':
      message = 'Error de servidor, por favor, inténtelo más tarde.';
      break;
    default:
      message = errorId;
    }
    this.showInfoDialog('Error', message, 'error');

    return this.enableDialog();
  }

  protected isValid(): boolean {
    return (this.dialogInputs == null || this.dialogInputs.findIndex(i => i instanceof DialogInput ? i.isValid() == false : i instanceof DialogOption ? i.selected == null : true) < 0);
  }

  protected closeDialog(response: boolean) {
    if (response) {
      let outputs = [];
      this.dialogInputs?.forEach(
        i => {
          if (i instanceof DialogInput) {
            outputs[i.name] = i.value.trim();
          }
          else if (i instanceof DialogOption) {
            outputs[i.name] = i.selected;
          }
        }
      );
      this.resolve(outputs);
    }
    else {
      this.reject();
    }
    this.dialogInputs = null
    this.dialogShow = false;
  }

  private initDialog(title: string, message: string) {
    this.dialogTitle = title;
    this.dialogMessage = message;
    this.dialogStyle = "";
    this.isInfo = false;
  }

  private enableDialog(): Promise<any[]> {
    this.dialogShow = true;
    this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    return this.promise;
  }

  protected getDialogInput(input: DialogInput|DialogOption): DialogInput|null {
    return input instanceof DialogInput ? input : null;
  }

  protected getDialogOption(input: DialogInput|DialogOption): DialogOption|null {
    return input instanceof DialogOption ? input : null;
  }
}
