<div class="login">
@if(mode == Mode.PsswdLogin) {
  <div class="info">
    <img class="logo" src='/img/logo.png' alt='client logo' />
    <div>
      <lbt-input class="user" [(ngModel)]="user" placeholder="user" (focus)="focus($event)" (change)="change($event)"></lbt-input>
      <lbt-input class="password" type="password" [(ngModel)]="password" placeholder="password" (focus)="focus($event)" (change)="change($event)" (keyup.enter)="passwordLogin(user, password)"></lbt-input>
      <lbt-button class="button" (click)="passwordLogin(user, password)">V</lbt-button>
      <!-- div id="keyboard">
        <app-keyboard expanded="true"></app-keyboard>
      </div -->
    </div>
  </div>
} @else {
  <div class="info">
    <img class="logo" src='/img/logo.png' alt='client logo' />
    <div>
      <span class="message" (click)="mode = Mode.PsswdLogin">Introduzca su código o acerque su tarjeta al lector</span>
      <input id="nfcId" class="hide" #nfcIdValue />
      <button id="nfcDetected" class="hide" (click)="nfcLogin(nfcIdValue.value)"></button>
    </div>
    <span class="error">{{ error }}</span>
  </div>
  <div class="keys">
@for (key of keys; track key) {
    <lbt-button class="number"
        (click)="onKey(key)">
      {{ key }}
    </lbt-button>
}
    <lbt-button class="clear"
      (click)="clearForm()">
      C
    </lbt-button>
  </div>
  <div class="users">
@for (user of users; track user.id) {
    <lbt-button
        (click)="pinLogin(user.email)">
      {{ user.name }}
    </lbt-button>
}
  </div>
}
</div>