import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lbt-button',
  templateUrl: './lbt-button.component.html',
  styleUrl: './lbt-button.component.css',
  host: {
    '[class.focused]': 'focused',
    '[class.active]': 'active',
    '[class.disabled]': 'disabled',
    '[tabindex]': 'disabled ? -1 : 0',
    '(focus)': 'onFocusInternal(true)',
    '(focusout)': 'onFocusInternal(false)',
    '(blur)': 'onFocusInternal(false)',
    '(document:keydown.space)': 'active = true',
    '(document:keyup.space)': 'active = false',
    '(document:keydown.enter)': 'active = true',
    '(document:keyup.enter)': 'active = false',
  }
})
export class LbtButtonComponent {
  @Input() public disabled = false;
  @Output() click = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() focusout = new EventEmitter();

  protected onClickInternal(event: Event) {
    event.stopPropagation();
    if (this.disabled == false) {
      this.click.emit();
    }
  }

  protected onFocusInternal(focus: boolean) {
    if (focus != this.focused) {
      this.focused = focus;
      focus ? this.focus.emit() : this.focusout.emit();
    }
  }

  private _focused = false;
  protected set focused(value: boolean) {
    this._focused = value;
    if (value === false) {
      // if exiting focus while button active, deactive it.
      this._active = false;
    }
  }

  protected get focused(): boolean {
    return this._focused;
  }

  private _active = false;
  protected set active(value: boolean) {
    if (this.disabled === false && this.focused === true) {
      if (this._active === true && value === false) {
        // if this button has been activated and now gets deactivated, do click.
        this.click.emit();
      }
      this._active = value;
    }
  }

  protected get active(): boolean {
    return this._active;
  }
}
