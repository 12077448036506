interface AndroidApi {
    getDeviceId(): string;
}

declare var Android: AndroidApi;

import { Component, OnInit } from '@angular/core';

import { LoginService } from '../login.service';

enum Mode {
  PinLogin = 0,
  PsswdLogin
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
  private ls = LoginService.getInstance();

  public Mode = Mode; // Make Mode enum accessible from html
  public mode = Mode.PinLogin;
  public user = '';
  public password = '';
  public error = '';
  public hasNfc = false; // TODO: get this from AndroidApi
  private deviceId = typeof Android === 'undefined' || typeof Android.getDeviceId !== 'function' ? null : Android.getDeviceId();
  //private deviceId = 'ac2a61351242753a';
  public keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  public users = [];
  private oldValue = '';
  private code = '';

  ngOnInit() {
    switch (this.ls.getRole()) {
    case 'client':
    case 'employee':
    case 'admin':
      window.location.assign('/tpv');
      break;
    default:
      this.clearForm();
      if (this.deviceId != null) {
        this.mode = Mode.PinLogin;
        this.shuffleKeys();
        // get users allowed to login
        this.ls.getDeviceUsers(this.deviceId, u => this.users = u);
      } 
      else {
        this.mode = Mode.PsswdLogin;
      }
      break;
    }
  }

  public focus(event: any): void {
    this.oldValue = event.target.value;
  }

  public change(event: any): void {
    if (this.oldValue === event.target.value) {
      if (event.target.nextElementSibling.select) {
        event.target.nextElementSibling.select();
      } else {
        this.login(null, this.user, this.password);
      }
    }
  }

  public onKey(key: number): void {
    this.code += key;
  }

  public nfcLogin(nfcId: string): void {
    this.login(nfcId);
  }

  public pinLogin(user: string): void {
    this.login(null, this.code, user)
  }

  public passwordLogin(user: string, password: string): void {
    this.login(null, null, user, password);
  }

  private login(nfcId: string = null, pin: string = null, user: string = null, password: string = null): void {
    this.ls.login(null, this.deviceId, user, password, +pin, nfcId, _r => {}, (_, m) => this.clearForm(m));
    this.clearForm();
  }

  public clearForm(error: string = ''): void {
    // don't clear user
    this.password = '';
    this.error = error;
    this.code = '';
    this.shuffleKeys();
  }

  private shuffleKeys(): void {
    for (let i = this.keys.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.keys[i], this.keys[j]] = [this.keys[j], this.keys[i]];
    }
  }
}
