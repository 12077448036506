
import { JWT } from '../jwt/jwt.helper';
import { Base64Url } from '../utils/base64Utils';
import { HashUtils } from '../utils/hashUtils';

const XOR = [0xcc, 0xc5, 0x5c, 0x55];
const NUM_TS = 12;

export class Request {
  private type: string;
  protected lang: string|null;

  constructor(type: string, lang: string|null = null) {
    this.type = type;
    this.lang = lang;
  }

  get json(): string {
    return JSON.stringify(this);
  }
};

export class UniqueRequest extends Request {
  private uuid: string; // Add uuid to avoid collapsing into one identical requests, like addCommand for cover fees.

  constructor(type: string) {
    super(type);
    this.uuid = crypto.randomUUID();
  }
}

export class Response {
  constructor(json: any) {}
};

export class SuccessResponse extends Response {
  success: boolean;

  constructor(json: any[]) {
    super(json);
    this.success = json['success'];
  }
}

class Callbacks<ResponseType extends Response> { 
  onResponse: (response: ResponseType) => void;
  onError: (code: number, message: string) => void;

  constructor(onResponse: (response: any) => void, onError: (code: number, message: string) => void) {
    this.onResponse = onResponse;
    this.onError = onError;
  }
}

export class Endpoint {
  protected prefix = '/api/v2/';
  private static auth = new JWT('elderbar.token');
  private requestMap = new Map<string, Callbacks<Response>[]>;
  private pt:Uint8Array|null = null;

  public sendRequest<RequestType extends Request, ResponseType extends Response>(endpoint: string, request: RequestType,
      responseClass: new(json: any[]) => ResponseType, onResponse: (response: ResponseType) => void, onError: (code: number, message: string) => void) {
    const jsonRequest = request.json;
    const callbacks = this.requestMap.get(jsonRequest);
    if (callbacks == undefined) {
      this.requestMap.set(jsonRequest, [new Callbacks(onResponse, onError)]);
      fetch(endpoint, this.getRequestMessage(request))
        .then((response) => {
          const callbacks = this.requestMap.get(jsonRequest);
          this.requestMap.delete(jsonRequest);
          if (response.status == 401) {
            Endpoint.auth.clearActiveToken();
          }
          if (callbacks == undefined) {
            console.error(`Response received for ${jsonRequest} but no callback is registered`);
          }
          else if (response.ok) {
            response.text().then(s => {
              try {
                const jsonResponse = JSON.parse(s);
                callbacks.filter(c => c.onResponse != null).forEach(c => c.onResponse(new responseClass(jsonResponse)));
              }
              catch (e) {
                console.error(`Request >>${jsonRequest}<< produced a response >>${s}<< that could not be parsed.`);
              }
            });
          }
          else {
            console.error(`Request ${jsonRequest} returned ERROR ${response.status}: ${response.statusText}`);
            response.text().then(s => callbacks.filter(c => c.onError != null).forEach(c => c.onError(response.status, JSON.parse(s)['message'])));
          } 
        })
      .catch((error) => {
        this.requestMap.delete(jsonRequest);
        console.error(`Could not fetch request ${jsonRequest} due to ERROR ${error}`);
      });
    }
    else {
      // Insert request into the list of callbacks, response will be sent once current request is returned
      callbacks.push(new Callbacks(onResponse, onError));      
    }
  }

  public subscribeToToken(onTokenUpdated: (token: any) => void) {
     return Endpoint.auth.subscribeToToken(onTokenUpdated);
  }

  public storeToken(token: string, idPath: string[]): boolean {
    return Endpoint.auth.storeToken(token, idPath);
  }

  public getActiveToken(): string {
    return Endpoint.auth.getActiveToken();
  }

  public getActiveTokenHeader(): any {
    return Endpoint.auth.getActiveTokenHeader();
  }

  public getActiveTokenPayload(): any {
    return Endpoint.auth.getActiveTokenPayload();
  }

  public getActiveTokenSignature(): any {
    return Endpoint.auth.getActiveTokenSignature();
  }

  public clearActiveToken(): void {
    Endpoint.auth.clearActiveToken();
  }

  public getUserPermission(): string {    
    const token = this.getActiveTokenPayload();
    return token ? token['permission']['role'] : null;
  }

  protected s() {
    this.pt = crypto.getRandomValues(new Uint8Array(NUM_TS << 1));
  }

  protected c() {
    this.pt = null;
  }

  protected t(i: number, ts: number) {
    if (this.pt != null && i < NUM_TS) {
      i += NUM_TS;
      this.pt[i] = 0;
      for (let t = ts; t > 1; t = Math.round(t/10)) {
        this.pt[i] = (this.pt[i] & 0xe0) + 0x20 + t % 11;
      }
    }
  }

  // this builds a security token that will be parsed in the server according to each request definition
  // this is meant to protect public API to be spammed, it will allow, deny or request additional input depending on its validity score
  private gpt(d: string) {
    if (this.pt != null) {
      const currentPt = this.pt;
      let pt = Base64Url.byteEncode(currentPt.slice(0, NUM_TS)) + '.' + Base64Url.byteEncode(currentPt.slice(NUM_TS).map((b, i) => b ^ currentPt[i] ^ XOR[i%XOR.length])) + '.';
      return pt + HashUtils.cyrb53(d + pt);
    }
    return null;
  }

  private getRequestMessage<RequestType extends Request>(request: RequestType): any {
    const body = request.json; 
    return {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
        'Authorization': Endpoint.auth.hasTokens() ? `Bearer ${Endpoint.auth.getActiveToken()}` : '',
        'Token': this.gpt(body)
      }
    };
  }
}
